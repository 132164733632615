import { Button } from 'components/shared/button';
import React from 'react';

import styles from './hero.module.scss';
import Background from './images/background-image-2xl.svg';
import BackgroundMd from './images/background-image-md.svg';
import BackgroundSm from './images/background-image-sm.svg';

const Hero = () => (
  <section className={`container safe-paddings ${styles.relative}`}>
    <img
      className={styles.backgroundIllustration}
      src={Background}
      width={1214}
      height={480}
      loading="eager"
      alt=""
      aria-hidden
    />

    <img
      className={styles.backgroundIllustrationMd}
      src={BackgroundMd}
      width={768}
      height={412}
      loading="eager"
      alt=""
      aria-hidden
    />

    <img
      className={styles.backgroundIllustrationSm}
      src={BackgroundSm}
      width={414}
      height={412}
      loading="eager"
      alt=""
      aria-hidden
    />

    <div className={styles.wrapper}>
      <h1 className={styles.title}>Hybrid Performance Testing for Websites</h1>
      <p className={styles.description}>End-to-end browser testing and load testing in the same k6 script</p>
      <div className={styles.buttonWrapper}>
        <Button
          className={styles.button}
          tag="a"
          size="sm"
          href={'https://grafana.com/docs/k6/latest/javascript-api/k6-experimental/browser/'}
        >
          k6 browser API
        </Button>
        <Button
          className={styles.button2}
          tag="a"
          size="sm"
          href={'https://grafana.com/docs/k6/latest/using-k6-browser/'}
          theme="accent-primary"
          cursor
        >
          Get Started
        </Button>
      </div>
    </div>
  </section>
);

export default Hero;
