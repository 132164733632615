import { Button } from 'components/shared/button';
import React from 'react';

import BackgroundMd from './images/background-image-md.svg';
import BackgroundSm from './images/background-image-sm.svg';
import Background from './images/background-image.svg';
import styles from './perfomance-testing-cta.module.scss';

const PerformanceTestingCTA = () => (
  <section className={`container safe-paddings ${styles.relative}`}>
    <img
      className={styles.backgroundIllustration}
      src={Background}
      width={1214}
      height={480}
      loading="lazy"
      alt=""
      aria-hidden
    />

    <img
      className={styles.backgroundIllustrationMd}
      src={BackgroundMd}
      width={768}
      height={412}
      loading="eager"
      alt=""
      aria-hidden
    />

    <img
      className={styles.backgroundIllustrationSm}
      src={BackgroundSm}
      width={414}
      height={412}
      loading="eager"
      alt=""
      aria-hidden
    />
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Ready to try the k6 browser module?</h2>
      <div className={styles.buttonWrapper}>
        <Button className={styles.button} tag="a" size="sm" href={'https://k6.io/docs/javascript-api/k6-browser/'}>
          Get Started
        </Button>
      </div>
    </div>
  </section>
);

export default PerformanceTestingCTA;
