/* eslint-disable max-len */
import classNames from 'classnames/bind';
import React from 'react';

import CollaborationIllustration from './images/collaboration-illustration.svg';
import SpotsErrorsIllustration from './images/spots-errors-illustration.svg';
import ExperienceIllustration from './images/user-experience-illustration.svg';
import styles from './same-tool.module.scss';

const cx = classNames.bind(styles);

const items = [
  {
    illustration: ExperienceIllustration,
    title: 'Get closer to your user experience',
    description:
      'Get insights to your user experience by also capturing browser performance metrics alongside existing protocol metrics.',
  },
  {
    illustration: SpotsErrorsIllustration,
    title: 'Find blind spots and errors',
    description:
      // eslint-disable-next-line max-len
      "Browser-based performance testing can catch issues related to browsers that won't be detected on the protocol-level.",
  },
  {
    illustration: CollaborationIllustration,
    title: 'Cross-team collaboration',
    description:
      'Developers, test automation engineers, SDETs can use the same tool and collaborate on performance testing.',
  },
];

const SameTool = () => (
  <section className={cx('wrapper', 'safe-paddings', 'container')}>
    <hgroup>
      <h2 className={cx('title')}>
        Use the same tool for frontend <br />
        and backend performance testing
      </h2>
      <p className={cx('description')}>
        Run a bulk of load on the protocol-level and spin up a few virtual users at the browser level <br />
        for a hybrid approach and get a holistic view of your application&apos;s performance
      </p>
    </hgroup>

    <ul className={cx('items')}>
      {items.map(({ illustration, title, description }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={cx('item-wrapper')} key={index}>
          <img className={cx('item-illustration')} src={illustration} width={384} height={260} alt="" />
          <div className={cx('item-content-wrapper')}>
            <h3 className={cx('item-title')}>{title}</h3>
            <p className={cx('item-description')}>{description}</p>
          </div>
        </li>
      ))}
    </ul>
  </section>
);

export default SameTool;
