import classNames from 'classnames/bind';
import React from 'react';

import styles from './how-it-works.module.scss';

const cx = classNames.bind(styles);
const VIDEO_SRC = 'https://www.youtube.com/embed/N7VJ9X5yAKo?&rel=0';

const HowItWorks = () => (
  <section className={cx('wrapper', 'safe-paddings', 'container')}>
    <hgroup>
      <h2 className={cx('title')}>How it works</h2>
      <p className={cx('description')}>Leverage existing k6 features and use a familiar API inspired by Playwright</p>
    </hgroup>
    <iframe
      className={cx('illustration')}
      allow="autoplay; picture-in-picture; web-share"
      src={VIDEO_SRC}
      title="How it works video"
      width="800"
      height="450"
      allowFullScreen
    />
  </section>
);

export default HowItWorks;
