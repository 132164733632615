import classNames from 'classnames/bind';
import React from 'react';

import BlockquoteIcon from './images/blockquote.inline.svg';
import JohnHillPhoto from './images/john-photo.jpg';
import styles from './testimonials.module.scss';

const cx = classNames.bind(styles);

const items = [
  {
    name: 'John Hill',
    position: ' Web UI Test Engineer, Space Mission Control Software',
    photo: JohnHillPhoto,
    // eslint-disable-next-line max-len
    text: 'The k6 browser module has changed my approach to automated performance testing. Now I’m able to test real user flows while using the same load testing tool to generate multiple users. All in the same test!',
  },
];

const Testimonials = () => (
  <section className={cx('wrapper', 'safe-paddings', 'container')}>
    <BlockquoteIcon className={cx('blockquote-icon')} />

    <ul className={cx('items')}>
      {items.map(({ photo, name, position, text }, index) => (
        <li className={cx('item-wrapper')} key={index}>
          <figure>
            <blockquote className={cx('item-text')}>{text}</blockquote>
            <figcaption className={cx('item-content-wrapper')}>
              <img className={cx('item-photo')} src={photo} width={40} height={40} alt="" />
              <span className={cx('item-name')}>{`${name}\u00A0`}</span>
              <span className={cx('item-position')}>{`— ${position}`}</span>
            </figcaption>
          </figure>
        </li>
      ))}
    </ul>
  </section>
);

export default Testimonials;
