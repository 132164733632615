import { SEO } from 'components/blocks/seo';
import Hero from 'components/pages/hybrid-performance-testing/hero/hero.view';
import HowItWorks from 'components/pages/hybrid-performance-testing/how-it-works/how-it-works.module.view';
// eslint-disable-next-line max-len
import PerformanceTestingCTA from 'components/pages/hybrid-performance-testing/perfomance-testing-cta/perfomance-testing-cta.module.view';
import SameTool from 'components/pages/hybrid-performance-testing/same-tool/same-tool.module.view';
import Testimonials from 'components/pages/hybrid-performance-testing/testimonials';
import { graphql } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

import IsolateTesting from '../components/pages/hybrid-performance-testing/isolate-testing';

function PerformanceTesting() {
  return (
    <DefaultLayout>
      <Hero />
      <IsolateTesting />
      <SameTool />
      <HowItWorks />
      <Testimonials />
      <PerformanceTestingCTA />
    </DefaultLayout>
  );
}

export default PerformanceTesting;

export const query = graphql`
  query {
    ogImage: file(absolutePath: { regex: "/hybrid-performance-testing/hybrid-performance-testing-meta.png/" }) {
      childImageSharp {
        resize(width: 1200, quality: 95) {
          src
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const pageMetaData = {
    data: {
      ...SEO_DATA['hybrid-performance-testing'].data,
      image: data.ogImage,
    },
  };

  return <SEO {...pageMetaData} />;
};
