/* eslint-disable max-len */

import classNames from 'classnames/bind';
import React from 'react';

import Illustration2 from './images/browser.svg';
import Illustration from './images/protocol.svg';
import styles from './isolate-testing.module.scss';

const cx = classNames.bind(styles);

const items = [
  '- Ignores user experience as it only focuses on what is happening on the backend, not the frontend',
  '- Scripts can get lengthy to create if you are trying to mimic complex user flows',
  '- More difficult to maintain as your website grows',
];

const items2 = [
  '- Frontend performance does not look under the hood',
  '- Without load, it does not test under traffic conditions',
  '- Browser-level load testing is resource intensive and costly',
];

const IsolateTesting = () => (
  <section className="safe-paddings container">
    <div className={cx('wrapper')}>
      <div className={cx('col-lg-5 col-md-6 col-sm-12')}>
        <div className={cx('illustration-wrapper')}>
          <img
            className={cx('illustration')}
            src={Illustration}
            height={386}
            width={520}
            alt="Load testing a website on the protocol level illustration"
            loading="lazy"
          />
        </div>
      </div>
      <div className={cx('col-lg-5 col-md-6 col-sm-12')}>
        <div className={cx('content-wrapper')}>
          <h3 className={cx('content-title')}>The limitations of load testing a website on the protocol level</h3>
          <ul className={cx('items')}>
            {items.map((item, index) => (
              <li className={cx('item-wrapper')} key={index}>
                <p className={cx('item-description')}>{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>

    <div className={cx('wrapper-2')}>
      <div className={cx('col-lg-5 col-md-6 col-sm-12')}>
        <div className={cx('content-wrapper2')}>
          <h3 className={cx('content-title')}>The limitations of only doing frontend performance</h3>
          <ul className={cx('items')}>
            {items2.map((item, index) => (
              <li className={cx('item-wrapper')} key={index}>
                <p className={cx('item-description')}>{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={cx('col-lg-5 col-md-6 col-sm-12')}>
        <div className={cx('illustration-wrapper2')}>
          <img
            className={cx('illustration')}
            src={Illustration2}
            height={399}
            width={520}
            alt="Performance testing on the browser level illustration"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </section>
);

export default IsolateTesting;
